import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import classnames from 'classnames';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import useTracker from 'src/lib/js/hooks/useTracker';

import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import { ModalContent, useModalContext, ModalCloseButton } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { asValidPhoneCountryCode } from 'shared/js/phoneUtils';

import { CompleteProfileForm } from 'public/components/default_template/online_ordering/account/completeProfileForm/CompleteProfileForm';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { ConfirmCodeForm } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';


type FormInputs = {
  yourInfoPhone: string;
}
export const PHONE_FOCUS_MSG = 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.';

export const PwlessAuth = (
  { checkingOut = false, onClickCheckoutAsGuest, source, onSuccessfulLogin }:
  {checkingOut?: boolean, onClickCheckoutAsGuest?: () => void, source: AuthenticationSource, onSuccessfulLogin?: () => void}
) => {
  const { onClose } = useModalContext();
  const [needsSignup, setNeedsSignup] = useState(false);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: { yourInfoPhone: '' }
  });
  const { customer, passwordlessLogin, fetchCustomer, completeSignup } = useCustomer();
  const [sentCode, setSentCode] = useState(false);
  const [error, setError] = useState('');
  const tracker = useTracker();

  const { ooRestaurant } = useRestaurant();
  const country = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });
  const parsedPhoneNumber = useMemo(() =>
    isValidPhoneNumber(phoneNumber, country) ? parsePhoneNumber(phoneNumber, country) : null
  , [country, phoneNumber]);

  const validatePhone = useCallback((value: string) =>
    // Don't use the memoized parsedPhoneNumber declared above because this is a callback to be passed for form validation
    isValidPhoneNumber(value, country) || 'Enter a valid local phone number'
  , [country]);

  const onSubmitPhone = useCallback(async () => {
    if(!parsedPhoneNumber) return;

    // If it is a valid local phone number in the country of the rx, send the code
    setSentCode(true);
    tracker.track('Clicked Send code', {
      source,
      countryCode: parsedPhoneNumber.country
    });

    if(!await passwordlessLogin(parsedPhoneNumber.number)) {
      setError('Error sending confirmation code');
      setSentCode(false);
    } else {
      setError('');
    }
  }, [passwordlessLogin, parsedPhoneNumber, tracker, source]);

  const onSuccessfulAccountCreation = useCallback(() => {
    onClose();
    toast.success(customer?.firstName ? `Welcome, ${customer?.firstName}!` : 'Welcome!');
  },
  [customer, onClose]);


  const onLogin = useCallback(async () => {
    const fetchedCustomer = await fetchCustomer();
    if(!fetchedCustomer) {
      setNeedsSignup(true);
    } else {
      onClose();
      toast.success(fetchedCustomer?.firstName ? `Welcome back, ${fetchedCustomer?.firstName}!` : 'Welcome back!');
      tracker.track('Completed 2FA', { source, newOrExistingAccount: 'existing account' });
      completeSignup(fetchedCustomer?.email || '', fetchedCustomer?.firstName || '', fetchedCustomer?.lastName || '');
      if(onSuccessfulLogin) {
        onSuccessfulLogin();
      }
    }
  }, [fetchCustomer, onClose, tracker, source, completeSignup, onSuccessfulLogin]);

  const handlePhoneInputKeydown = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      onSubmitPhone();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const headerId = 'pwless-auth-modal-header';

  return (
    <ModalContent contentClassName="pwlessModalContent" slideIn slideOut data-testid="PwlessAuth" ariaLabelledBy={headerId}>
      <div className="modalHeader" data-testid="PwlessAuth">
        <h2 className="header" id={headerId}>{needsSignup ? 'Complete your profile' : sentCode ? 'Confirm it\'s you' : 'Enter your mobile number'}</h2>
        <ModalCloseButton />
      </div>
      {needsSignup
        ? <CompleteProfileForm phoneNumber={phoneNumber} setHasSubmitted={onSuccessfulAccountCreation} checkingOut={checkingOut} />
        :
        <FormProvider {...formMethods}>
          <div className="body" id="pwlessauthcontent">
            {!sentCode || parsedPhoneNumber == null ?
              <>
                <p>If you don&apos;t have a Toast account, we will help you create one.</p>
                <PhoneInput
                  id="yourInfoPhone"
                  aria-labelledby="pwlessauthcontent"
                  required
                  label="Phone number"
                  validate={validatePhone}
                  autoComplete="tel"
                  defaultValue={customer?.phone || ''}
                  warning={error}
                  focusMessage={PHONE_FOCUS_MSG}
                  filled={Boolean(customer?.phone)}
                  disabled={needsSignup}
                  unregisterOnUnmount={false}
                  onKeyDown={handlePhoneInputKeydown}
                  shouldUnFocus={false}
                  initialFocus={true}
                  button={
                    <button className={classnames('inputButton', !sentCode && phoneNumber && parsedPhoneNumber?.isValid() && 'active')}
                      onClick={onSubmitPhone} onKeyDown={handlePhoneInputKeydown} data-testid="auth-send-code">Send code
                    </button>
                  } />
              </> :
              <>
                <p>{`Enter the code sent to ${parsedPhoneNumber.formatNational()} to confirm it's you.`}</p>
                <ConfirmCodeForm phoneNumber={parsedPhoneNumber.number} newAccount={false} onComplete={onLogin} isCheckout={false} />
                <div className="checkoutMode">
                  <button className="guestCheckout" onClick={onSubmitPhone}>
                    <div className="text">Resend code</div>
                  </button>
                </div>
              </>}
          </div>
        </FormProvider>}
      {checkingOut &&
        <div
          tabIndex={0}
          role="button"
          className="guestCheckoutLink"
          onClick={() => { onClose(); onClickCheckoutAsGuest?.(); }}
          onKeyDown={e => {
            if(e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault(); onClose(); onClickCheckoutAsGuest?.();
            }
          }}>
          {needsSignup ? 'Not now, checkout as a guest' : 'Checkout as a guest'}
        </div>}
    </ModalContent>
  );
};
